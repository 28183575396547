import React from "react";
import styled from "styled-components";
import * as ReactScroll from "react-scroll";
import { connect } from "react-redux";
import { withPage } from "../Page";
import IntroItem from "../Components/GeneralItem";
import Selectors from "../Selectors";
import * as L from "../Utils/Lang";
import { StaticFluidImage } from "../Components/StaticImage";

class HomePage extends React.Component {
  render() {
    let { Lang } = this.props;
    return (
      <Wrapper banner={"images/home-banner.png"}>
        <div className="banner">
          <div className="blur-banner">
            <StaticFluidImage path="home-banner.png" />
          </div>
          <SloganWrapper>
            <Slogan large css={"margin-bottom: 30px;"}>
              {L.s("homeTitle")}
            </Slogan>
            <Slogan>{L.s("homeSubtitle")}</Slogan>
          </SloganWrapper>
        </div>

        <IntroWrapper>
          <IntroItem
            itemName="home-1"
            img="images/2.png"
            title={L.s("howOperate")}
            description={L.s("howOperateContent")}
            button
            toElementName="home-2"
          />
          <IntroItem
            itemName="home-2"
            img="images/3.png"
            title={L.s("whyM2m")}
            description={L.s("whyM2mContent")}
            reverse
            button
            toElementName="home-3"
          />
          <IntroItem
            itemName="home-3"
            img="images/4.png"
            title={L.s("clientOpinion")}
            description={L.s("clientOpinionContent")}
          />
        </IntroWrapper>

        {Lang === "en" && (
          <>
            <AdvantageWrapper>
              <h1>Our advantage</h1>
              <Row>
                <RowItem>
                  <IconSmall img="../../images/icon/1.png" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>Customised Solutions</h4>
                    <p>Tailored plans for Voice, Data, IOT and M2M</p>
                  </div>
                </RowItem>

                <RowItem>
                  <IconSmall img="../../images/icon/2.png" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>Global Coverage</h4>
                    <p>
                      Enjoy multi-network mobile coverage in various continents
                    </p>
                  </div>
                </RowItem>

                <RowItem>
                  <IconSmall img="../../images/icon/3.png" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>Single Global SIM</h4>
                    <p>Sufficient for provisioning IoT services universally</p>
                  </div>
                </RowItem>
              </Row>
              <Row>
                <RowItem>
                  <IconSmall img="../../images/icon/4.png" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>Simplified SIM Management</h4>
                    <p>
                      An engine behind your complete control for your entire SIM
                      portfolio
                    </p>
                  </div>
                </RowItem>

                <RowItem>
                  <IconSmall img="../../images/icon/5.png" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>Support</h4>
                    <p>
                      Provides industry leading support and best quality service
                      worldwide
                    </p>
                  </div>
                </RowItem>

                <RowItem>
                  <IconSmall img="../../images/icon/6.png" />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h4>Notifications And Alarms</h4>
                    <p>
                      Set usage and personalized alerts when thresholds are
                      reached
                    </p>
                  </div>
                </RowItem>
              </Row>
            </AdvantageWrapper>

            <SolutionWrapper>
              <h1>Our solutions</h1>
              <Row>
                <ColItem>
                  <IconSquare img="../../images/icon/7.png" />
                  <Col>
                    <h4>FULL API SUITE</h4>
                    <div
                      style={{
                        backgroundColor: "#3fb3ff",
                        width: 40,
                        height: 4
                      }}
                    />
                    <p>
                      Don’t struggle to access the data you want to expose,
                      instead unlock it quickly and easily with built-in
                      integration that…
                    </p>
                  </Col>
                </ColItem>

                <ColItem>
                  <IconSquare img="../../images/icon/8.png" />
                  <Col>
                    <h4>GLOBAL ROAMING</h4>
                    <div
                      style={{
                        backgroundColor: "#3fb3ff",
                        width: 40,
                        height: 4
                      }}
                    />
                    <p>
                      With over 20 global MNO relationships, we take advantage
                      of a range of different levels of connectivity available
                      to all…
                    </p>
                  </Col>
                </ColItem>
              </Row>
              <Row>
                <ColItem>
                  <IconSquare img="../../images/icon/9.png" />
                  <Col>
                    <h4>NON – STEERED SIM</h4>
                    <div
                      style={{
                        backgroundColor: "#3fb3ff",
                        width: 40,
                        height: 4
                      }}
                    />
                    <p>
                      Network Based Steering is needful to find the best
                      available and most reliable coverage for your SIM. Our
                      SIMs is the best option , although your connection is
                      lost, you still can access to the other best network
                      coverages available in your location.
                    </p>
                  </Col>
                </ColItem>

                <ColItem>
                  <IconSquare img="../../images/icon/10.png" />
                  <Col>
                    <h4>SIM CONTROL & MANAGEMENT</h4>
                    <div
                      style={{
                        backgroundColor: "#3fb3ff",
                        width: 40,
                        height: 4
                      }}
                    />
                    <p>
                      TEL-U is master in supplying IoT solutions suitable for
                      usage on a management platform. The range of management
                      services have been carefully devised to support the
                      allocation process, while effectively increasing the short
                      and long term management of any M2M systems under
                      operation.
                    </p>
                  </Col>
                </ColItem>

                <ColItem>
                  <IconSquare img="../../images/icon/11.png" />
                  <Col>
                    <h4>BILLING MANAGEMENT</h4>
                    <div
                      style={{
                        backgroundColor: "#3fb3ff",
                        width: 40,
                        height: 4
                      }}
                    />
                    <p>
                      TEL-U provides the platform that gives you accurate
                      billing experience, fully integrated with your
                      organization’s administrative systems and procedures. Our
                      Billing platform gives you complete usage and financial
                      visibility across your entire subscribed SIM estate.
                    </p>
                  </Col>
                </ColItem>
                <ColItem>
                  <IconSquare img="../../images/icon/12.png" />
                  <Col>
                    <h4>NOTIFICATIONS & ALARMS</h4>
                    <div
                      style={{
                        backgroundColor: "#3fb3ff",
                        width: 40,
                        height: 4
                      }}
                    />
                    <p>
                      In SIM cost management terms, ‘bill shock’ where
                      unanticipate excess data is used and billed, we are
                      constantly sending notifications…
                    </p>
                  </Col>
                </ColItem>
              </Row>
            </SolutionWrapper>
          </>
        )}
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background-color: black;
  & .blur-banner {
    @media screen and (max-width:700px) {
      display: none;
    } 
  }
  & > .banner {
    background-color: lightgray;
    position: relative;
    @media screen and (max-width:700px) {
      height: 90vh;
      background-image: url('${props => props.banner}');
      background-size: cover;
      background-repeat: none;
      background-position: center;
    } 
  }
`;
let Banner = styled.div`
    background-color: #ccc;
    background-image: url('${props => props.img}');
    background-size: cover;
    background-repeat: none;
    background-position: center;
    width: 100vw;
    height: 100vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:700px) {
    }
`;

let SolutionWrapper = styled.div`
  padding: 60px 30px;
  & > h1 {
    color: #3f88b3;
    text-align: center;
    margin-bottom: 20px;
  }
`;

let AdvantageWrapper = styled.div`
  padding: 60px 30px;
  & > h1 {
    color: #3f88b3;
    text-align: center;
    margin-bottom: 20px;
  }
`;

let Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 10px;
  }
`;

let Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 35px 0;
`;

let RowItem = styled.div`
  margin: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 400px;

  & h4 {
    font-size: 24px;
    color: #3f88b3;
    margin-bottom: 10px;
  }

  & p {
    color: #ffffff;
  }
`;

let IconSmall = styled.div`
  width: 100px;
  height: 70px;
  background-image:url("${props => props.img}");
  background-repeat: no-repeat;
  background-size:contain;
  margin-right: 10px;
`;

let IconSquare = styled.div`
  width: 80px;
  height: 80px;
  background-image:url("${props => props.img}");
  background-repeat: no-repeat;
  background-size:contain;
  margin-bottom: 20px;
`;

let ColItem = styled.div`
  margin: 20px 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 250px;

  & h4 {
    font-size: 24px;
    text-align: center;
    color: #ffffff;
    margin: 20px 0px;
  }

  & p {
    text-align: center;
    color: #ffffff;
  }
`;

let SloganWrapper = styled.div`
  position: absolute;
  padding: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  @media screen and (max-width: 700px) {
    /* position: static;
    transform: none;
    padding: 20px;
    padding-top: 100px; */
    transform: translate(-50%, -40%);
  }
`;

let Slogan = styled.div`
  font-size: ${props => (props.large ? 40 : 18)}px;
  font-weight: ${props => (props.large ? 500 : 400)};
  color: ${props => (props.large ? "#418DB9" : "#fff")};
  line-height: ${props => (props.large ? 55 : 33)}px;
  letter-spacing: 1px;
  text-align: left;

  @media screen and (max-width: 700px) {
    font-size: ${props => (props.large ? 26 : 16)}px;
    line-height: ${props => (props.large ? 30 : 20)}px;
  }
  ${props => props.css}
`;

let IntroWrapper = styled.div``;

export default withPage(
  connect((state, ownProps) => ({
    Lang: Selectors.getLang(state)
  }))(HomePage)
);
